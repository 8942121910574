import React from "react";
import { connect } from "react-redux";

import { Spinner } from "react-activity";

import {
  authenticationActions,
  deviceActions,
  loanActions,
} from "../../store/store";

import { ChooseFlowContainer } from "../choose_flow/choose_flow.container";
import { CreditCheckContainer } from "../credit_check/credit_check.container";
import { AuthenticationContainer } from "../two_factor_auth/auth_request.container";
import { AuthenticationWaitingContainer } from "../two_factor_auth/auth_waiting.container";
import { AuthenticationConfirmContainer } from "../two_factor_auth/auth_confirm.container";
import { AuthenticationStartContainer } from "../two_factor_auth/auth_start.container";
import { IntakeInProgressContainer } from "../intake_in_progress/intake_in_progress";
import { IntakeContainer } from "../intake/intake.container";
import { InterviewContainer } from "../interview/interview.container";
import { AssessmentStudyContainer } from "../assessment_study/assessment_study.container";
import { EducationalIntakeContainer } from "../educational_intake/educational_intake.container";
import { EducationalReviewContainer } from "../educational_review/educational_review.container";
import { ApplicationContainer } from "../application/application.container";
import { ReviewContainer } from "../review/review.container";
import { SupervisionContainer } from "../supervision/supervision.container";

import { GuarantorCreditCheckContainer } from "../guarantor_credit_check/guarantor_credit_check.container";
import { GuarantorContainer } from "../guarantor/guarantor.container";
import { GroupGuarantorIntakeIncompleteContainer } from "../intake_incomplete/group_guarantor_intake_incomplete";
import { GuarantorSigningContainer } from "../guarantor_signing/guarantor_signing.container";
import { AssessmentContainer } from "../assessment/assessment.container";
import { SignContainer } from "../sign/sign.container";

import { Button } from "../../components/button/button";
import { Navigation } from "../../components/navigation/navigation.component";
import { ws_whatsapp } from "../../components/icon/icon";
import { setAlertDialog } from "../../components/alert/alert";
import { translate } from "../../lib/intl";
import { colors } from "../../theme/theme";
import { openInWhatsapp } from "../../lib/helpers";
import { api } from "../../app";
import "./home.switch.scss";
import { Message } from "./message";

import Config from "../../config";

class HomeSwitch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      uuid: props.match.params.uuid,
      token: props.match.params.token,

      pending: true,
    };

    // log-out if you are already authenticated and token is different
    if (
      this.props.device.isAuthenticated &&
      this.props.device.token.access_token !== this.state.token
    ) {
      this.props.resetStates();
    }

    if (!this._checkBrowserCompatibility()) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.pending = false;
      return;
    }

    if (
      this.props.device.isAuthenticated &&
      this.props.device.token.access_token === this.state.token
    ) {
      // if you are already logged in, with the same token, then just retrieve the loan
      this._retrieve_loan();
    } else {
      // login and retrieve loan assessment
      this.props.loginWithAccessToken(this.state.token).then((profile) => {
        if (profile) {
          this._retrieve_loan();
        } else {
          // wrong profile
          this.setState({ pending: false });
        }
      });
    }
  }

  _checkBrowserCompatibility() {
    // check if the browser is missing support for critical JS features
    if (!window.Promise || !window.fetch || !window.Blob || !Object.values) {
      setAlertDialog("Sorry", translate("errors.contact_us"));
      return false;
    }
    return true;
  }

  user_states = (loan) => {
    let all_user_states = {
      waiting_for_recurring_client_data: <Message message="processing" />,
      applying: <ApplicationContainer />,
      choose_intake_flow: <ChooseFlowContainer />,
      applying_intake: <IntakeContainer />,
      applying_educational_intake: <EducationalIntakeContainer />,
      applying_new_intake: <IntakeContainer />,
      applying_existing_intake: <EducationalIntakeContainer />,
      checking_intake: <ApplicationContainer />,
      applying_assessment: <AssessmentContainer />,
      applying_review: <ReviewContainer />,
      applying_educational_review: <EducationalReviewContainer />,
      signing: <SignContainer />,
      credit_check: <CreditCheckContainer />,
      interview: <InterviewContainer />,
      assessment_study: <AssessmentStudyContainer />,
      intake_in_progress: <IntakeInProgressContainer />,
      applying_supervision: <SupervisionContainer />,

      authenticate: <AuthenticationContainer />,
      request_authentication: <AuthenticationStartContainer />,
      authentication_waiting: <AuthenticationWaitingContainer />,
      authentication_valid: <AuthenticationConfirmContainer />,
      authentication_failed: <AuthenticationConfirmContainer />,

      guarantor_inviting: (
        <Message
          message="awaiting_guarantor"
          guarantor_phone={loan.guarantor_phone}
          loan_id={loan.id}
        />
      ),
      guarantor_applying: (
        <Message
          message="awaiting_guarantor"
          guarantor_phone={loan.guarantor_phone}
          loan_id={loan.id}
        />
      ),
      guarantor_authenticate: <AuthenticationContainer />,
      guarantor_request_authentication: <AuthenticationStartContainer />,
      guarantor_authentication_waiting: <AuthenticationWaitingContainer />,
      guarantor_authentication_valid: <AuthenticationConfirmContainer />,
      guarantor_authentication_failed: <AuthenticationConfirmContainer />,

      assessing: <Message message="processing" loan_id={loan.id} />,
      paying: <Message message="processing" loan_id={loan.id}/>,
      approved: <Message message="processing" loan_id={loan.id}/>,
      awaiting_payment_date: <Message message="processing" loan_id={loan.id}/>,

      outstanding: <Message message="submitted" loan_id={loan.id}/>,
      rejected: <Message message="loan_request_closed" loan_id={loan.id}/>,
      withdrawn: <Message message="loan_request_closed" loan_id={loan.id}/>,
    };

    let user_states = {};

    Config.user_states.reduce(function (map, state) {
      map[state] = all_user_states[state];
      return map;
    }, user_states);

    let states = user_states;

    if (states[loan.page] === undefined) {
      return <Message message="submitted" loan_id={loan.id}/>;
    }

    return states[loan.page];
  };

  guarantor_states = (loan) => {
    let all_guarantor_states = {
      guarantor_credit_check: <GuarantorCreditCheckContainer />,
      guarantor_credit_check_review: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      guarantor_review_credit_check: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      guarantor_applying: <GuarantorContainer />,
      guarantor_signing: <GuarantorSigningContainer />,
      group_guarantor_intake_incomplete: (
        <GroupGuarantorIntakeIncompleteContainer />
      ),
      group_guarantor_signing_incomplete: (
        <GroupGuarantorIntakeIncompleteContainer />
      ),

      guarantor_inviting: <Message message="guarantor_loan_reopened" loan_id={loan.id}/>,

      guarantor_authenticate: <AuthenticationContainer />,
      guarantor_request_authentication: <AuthenticationStartContainer />,
      guarantor_authentication_waiting: <AuthenticationWaitingContainer />,
      guarantor_authentication_valid: <AuthenticationConfirmContainer />,
      guarantor_authentication_failed: <AuthenticationConfirmContainer />,

      applying_review: <Message message="guarantor_loan_reopened" loan_id={loan.id}/>,
      applying_intake: <Message message="guarantor_loan_reopened" loan_id={loan.id}/>,
      applying_educational_intake: (
        <Message message="guarantor_loan_reopened" loan_id={loan.id}/>
      ),
      applying_educational_review: (
        <Message message="guarantor_loan_reopened" loan_id={loan.id}/>
      ),

      assessing: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      assessing_cpc: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      assessing_cac: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      signing: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      paying: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      approved: <Message message="guarantor_processing" loan_id={loan.id}/>,

      outstanding: <Message message="submitted_guarantor" loan_id={loan.id}/>,
      rejected: <Message message="loan_request_closed" loan_id={loan.id}/>,
      withdrawn: <Message message="loan_request_closed" loan_id={loan.id}/>,
      awaiting_payment_date: <Message message="processing" loan_id={loan.id}/>,
    };

    let guarantor_states = {};

    Config.guarantor_states.reduce(function (map, state) {
      map[state] = all_guarantor_states[state];
      return map;
    }, guarantor_states);

    let states = guarantor_states;

    if (states[loan.state] === undefined) {
      return <Message message="loan_request_closed" loan_id={loan.id}/>;
    }

    return states[loan.state];
  };

  _retrieve_loan = () => {
    // retrieve loan assessment
    this.props.getLoan(this.state.uuid).then((loan) => {
      if (loan) {
        // default to applying if user, otherwise set page to this
        if (
          loan.role === "user" &&
          ["applying_intake", "applying_assessment", "applying_review"].indexOf(
            loan.state
          ) > -1
        ) {
          this.props.setPage("applying");
        } else {
          // set page to state
          this.props.setPage(loan.state);
        }
      }

      // initial page is set to state
      this.setState({ pending: false });
    });
  };

  render = ({ history, account, device, loan, setLocale } = this.props) => {
    console.log("loan state: ", loan.role, loan.state);
    return (
      <div className="homeContainer">
        <Navigation
          history={history}
          currentLocale={device.locale}
          setLocale={setLocale}
        />

        {(this.state.pending ||
          loan.getLoanPending ||
          account.getAccountPending) && (
          <div className="loadingSpinner">
            <Spinner color="#eee" size={50} speed={1} />
          </div>
        )}

        {!this.state.pending &&
          !loan.getLoanPending &&
          !account.getAccountPending && (
            <div>
              {loan.uuid && device.isAuthenticated && (
                <div>
                  {loan.role === "user"
                    ? this.user_states(loan)
                    : this.guarantor_states(loan)}
                </div>
              )}

              {(!loan.uuid ||
                !device.isAuthenticated ||
                loan.role === "unauthorized") && (
                <div className="message">
                  <h2 className="bigSpacer">
                    {translate("sorry_not_found.header")}
                  </h2>
                  <p className="center bigSpacer">
                    {translate("sorry_not_found.desc")}
                  </p>
                  <p className="center bigSpacer">
                    {translate("sorry_not_found.desc2")}
                  </p>
                  <Button
                    style={{ width: "240px" }}
                    backgroundColor={colors.brand.secondary}
                    color="#fff"
                    title={translate("sorry_not_found.button_title")}
                    icon={ws_whatsapp}
                    onPress={() =>
                      openInWhatsapp(
                        api.endpoint.whatsapp_number,
                        `${translate("sorry_not_found.chat_subject")}: ${
                          this.props.uuid
                        }`
                      )
                    }
                  />
                </div>
              )}
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    device: state.device,
    account: state.account,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocale: (locale) => dispatch(deviceActions.setLocale(locale)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
    loginWithAccessToken: (accessToken, utm_campaign) =>
      dispatch(
        authenticationActions.loginWithAccessToken(accessToken, utm_campaign)
      ),
    getLoan: (uuid) => dispatch(loanActions.getLoan(uuid)),
    resetStates: () => dispatch(authenticationActions.resetStates()),
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeSwitch);

export { connectedContainer as HomeSwitch };
