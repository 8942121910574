import React from 'react';

import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';

import * as Sentry from '@sentry/react';
import packageInfo from '../../package.json';
import env from "../env";

export class Logger {
  constructor() {
    this.enableBugsnag = false;
    this.enableSentry = true;

    this.initBugsnag();
    this.initSentry();
  }

  initBugsnag() {
    if (!this.enableBugsnag) return;
    this.bugsnagClient = bugsnag('1d2f0b32e2ceac0dd10164e25bdbc98c');
    this.bugsnagErrorBoundary = this.bugsnagClient.use(createPlugin(React));
  }

  initSentry() {
    if (!this.enableSentry) return;
    Sentry.init({
      dsn: env.ENDPOINT === "production"
        ? "https://4353d057051141fc8b347befac6a5eba@o411110.ingest.sentry.io/5285924"
        : "https://72352fee812a425193c8a664df4cf2d3@o411110.ingest.sentry.io/5285925",
      environment: env.ENDPOINT,
      release: packageInfo.version,
      attachStacktrace: true,
      beforeSend: (event) => {
        // remove request url since it contains the auth key
        if (event && event.request && event.request.url) {
          event.request.url = undefined;
        }
        return event;
      }
    });
    this.sentryErrorBoundary = Sentry.ErrorBoundary;
  }

  leaveBreadcrumb(name, metaData, type, timestamp) {
    if (this.enableBugsnag) {
      this.bugsnagClient.leaveBreadcrumb(name, metaData, type, timestamp);
    }

    if (this.enableSentry) {
      Sentry.addBreadcrumb({
        message: name,
        data: metaData,
        type,
        timestamp,
      });
    }
  }

  captureException(exception, extra) {
    if (this.enableBugsnag) {
      this.bugsnagClient.notify(exception, extra ? { metaData: extra } : undefined);
    }

    if (this.enableSentry) {
      Sentry.captureException(exception, {extra: extra});
    }
  }

  captureMessage(message, extra) {
    if (this.enableSentry) {
      Sentry.captureMessage(message, {extra: extra});
    }
  }
}
