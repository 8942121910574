import { expressions } from "../../survey/validators";
import env from "../../env";
const required = env.ENDPOINT !== "local";

const page_1 = {
  name: "summary_presentation",
  title: {
    en: "SUMMARY PRESENTATION",
    "fr-CD": "PRESENTATION SOMMAIRE",
  },
  elements: [
    {
      type: "comment",
      name: "custom_textbox_1",
      title: {
        en: "Explain what your establishment/company does 'in 10 lines max.'",
        "fr-CD":
          "Expliquer ce que fait votre établissement / société en 10 lignes max",
      },
      isRequired: required,
      maxLength: 500,
    },
    {
      type: "comment",
      name: "custom_textbox_2",
      title: {
        en: "Explain the project 'in 5 lines max.'",
        "fr-CD": "Expliquer le projet en 5 lignes max",
      },
      isRequired: required,
      maxLength: 250,
    },
    {
      type: "dropdown",
      name: "custom_text_3",
      title: {
        en: "Select how many leaders are in the project",
        "fr-CD": "Sélectionner le nombre de porteurs / porteuses du projet.",
      },
      isRequired: required,
      choices: [
        {
          value: "1",
          text: "1",
        },
        {
          value: "2",
          text: "2",
        },
      ],
    },
    {
      type: "text",
      name: "custom_text_4",
      title: {
        en: "Provide key elements of the project leader(s) CV(s) 20 lines max",
        "fr-CD":
          "Donner des éléments clés du CV du porteur / euse de projet N° 1",
      },
      isRequired: required,
    },
    {
      type: "file_uploader",
      accept: "image/*,application/pdf",
      name: "file_1",
      title: {
        en: "Upload CV N° 1",
        "fr-CD": "Télécharger CV N° 1"
      },
      isRequired: required,
    },
    {
      type: "text",
      name: "custom_text_5",
      visibleIf: "{custom_text_3} = '2'",
      title: {
        en: "Provide key elements of the project leader(s) CV(s) 20 lines max",
        "fr-CD":
          "Donner des éléments clés du CV du porteur / euse de projet N° 2",
      },
      isRequired: required,
    },
    {
      type: "file_uploader",
      accept: "image/*,application/pdf",
      name: "file_9",
      visibleIf: "{custom_text_3} = '2'",
      title: {
        en: "Upload CV N° 2",
        "fr-CD": "Télécharger CV N° 2"
      },
      isRequired: required,
    },
  ],
};

const page_2 = {
  name: "information_on_the_project_leader",
  title: {
    en: "INFORMATION ON THE PROJECT LEADER",
    "fr-CD": "INFORMATION SUR LE / LA PORTEUR / EUSE DU PROJET",
  },
  elements: [
    {
      type: "text",
      name: "last_name",
      title: {
        en: "Last name",
        "fr-CD": "Nom de famille",
      },
      isRequired: required,
    },
    {
      type: "text",
      name: "first_name",
      title: {
        en: "First name",
        "fr-CD": "Prénom",
      },
      isRequired: required,
    },
    {
      type: "phone",
      name: "phone_number",
      title: {
        en: "Phone number",
        "fr-CD": "Téléphone",
      },
      isRequired: required,
      validators: [
        expressions.phone_en("phone_number"),
        expressions.phone_length("phone_number"),
      ],
    },
    {
      type: "dropdown",
      name: "custom_text_7",
      title: {
        en: "Gender",
        "fr-CD": "Sexe",
      },
      isRequired: required,
      choices: [
        {
          value: "m",
          text: {
            en: "Male",
            "fr-CD": "M",
          },
        },
        {
          value: "f",
          text: {
            en: "Female",
            "fr-CD": "F",
          },
        },
      ],
    },
    {
      type: "text",
      name: "date_of_birth",
      title: {
        en: "Date of Birth",
        "fr-CD": "Date de naissance"
      },
      isRequired: required,
      inputType: "date",
      validators: [
        {
          type: "expression",
          text: {
            en: "You must be older than 18 and younger than 35 to apply",
            "fr-CD": "Vous devez avoir plus de 18 ans et moins de 35 ans pour postuler",
          },
          expression: "(age({date_of_birth}) >= 18) && (age({date_of_birth}) <= 35)",
        },
      ],
    },
    {
      type: "dropdown",
      name: "custom_text_8",
      title: {
        en: "Identity document",
        "fr-CD": "Pièce d'identité (Passeport, carte d'électeur ou Autres)",
      },
      isRequired: required,
      choices: [
        {
          value: "passeport",
          text: {
            en: "Passport",
            "fr-CD": "Passeport",
          },
        },
        {
          value: "carte_d_electeur",
          text: {
            en: "voter_card",
            "fr-CD": "Carte d'électeur",
          },
        },
      ],
      hasOther: true,
      storeOthersAsComment: false,
      otherText: {
        en: "Other",
        "fr-CD": "Autres",
      },
    },
    {
      type: "text",
      name: "id_number",
      title: {
        en: "Identity document number",
        "fr-CD": "N° pièce d'identité",
      },
      isRequired: required,
    },
    {
      type: "panel",
      name: "current_address_panel",
      title: {
        en: "Current Address",
        "fr-CD": "Adresse du domicile"
      },
      isRequired: required,
      elements: [
        {
          type: "text",
          name: "custom_text_10",
          title: {
            en: "Time spent at current address",
            "fr-CD": "Temps passé à l'adresse actuelle",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_11",
          title: {
            en: "Number",
            "fr-CD": "Numéro",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_12",
          title: {
            en: "Street/Avenue",
            "fr-CD": "Rue ou Avenue",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_13",
          title: {
            en: "Neighbourhood",
            "fr-CD": "Quartier",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_14",
          title: {
            en: "Town",
            "fr-CD": "Commune",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_15",
          title: {
            en: "City",
            "fr-CD": "Ville",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_16",
          title: "Province",
          isRequired: required,
        },
        {
          type: "dropdown",
          name: "custom_text_17",
          title: {
            en: "Property situation",
            "fr-CD": "Situation de propriété",
          },
          isRequired: required,
          choices: [
            {
              value: "proprietaire",
              text: {
                en: "Owner",
                "fr-CD": "Propriétaire",
              },
            },
            {
              value: "locataire",
              text: {
                en: "Tenant",
                "fr-CD": "Locataire",
              },
            },
            {
              value: "sous_loge",
              text: {
                en: "Subtenant",
                "fr-CD": "Sous-Logé",
              },
            },
          ],
          hasOther: true,
          storeOthersAsComment: false,
          otherText: {
            en: "Other",
            "fr-CD": "Autres",
          },
        },
      ],
    },
    {
      type: "panel",
      name: "previous_address_panel",
      title: {
        en: "Previous Address",
        "fr-CD": "Adresse du domicile précédent",
      },
      isRequired: required,
      elements: [
        {
          type: "text",
          name: "custom_text_18",
          title: {
            en: "Time spent at previous address",
            "fr-CD": "Temps passé à l'adresse précédente",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_19",
          title: {
            en: "Number",
            "fr-CD": "Numéro",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_20",
          title: {
            en: "Street/Avenue",
            "fr-CD": "Rue ou Avenue",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_21",
          title: {
            en: "Neighbourhood",
            "fr-CD": "Quartier",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_22",
          title: {
            en: "Town",
            "fr-CD": "Commune",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_23",
          title: {
            en: "City",
            "fr-CD": "Ville",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_24",
          title: "Province",
          isRequired: required,
        },
        {
          type: "dropdown",
          name: "custom_text_25",
          title: {
            en: "Property situation",
            "fr-CD": "Situation de propriété",
          },
          isRequired: required,
          choices: [
            {
              value: "proprietaire",
              text: {
                en: "Owner",
                "fr-CD": "Propriétaire",
              },
            },
            {
              value: "locataire",
              text: {
                en: "Tenant",
                "fr-CD": "Locataire",
              },
            },
            {
              value: "sous_loge",
              text: {
                en: "Subtenant",
                "fr-CD": "Sous-Logé",
              },
            },
          ],
          hasOther: true,
          storeOthersAsComment: false,
          otherText: {
            en: "Other",
            "fr-CD": "Autres",
          },
        },
      ],
    },
    {
      type: "panel",
      name: "family_references_panel",
      title: {
        en: "Reference Person",
        "fr-CD": "Personnes de référence"
      },
      innerIndent: 1,
      isRequired: required,
      elements: [
        {
          type: "panel",
          name: "references_1_panel",
          title: {
            en: "Reference, 1st degree family member",
            "fr-CD": " Membre de famille 1er degré",
          },
          isRequired: required,
          elements: [
            {
              type: "text",
              name: "custom_text_26",
              title: {
                en: "Name",
                "fr-CD": "Nom et Prénom",
              },
              isRequired: required,
            },
            {
              type: "text",
              name: "custom_text_55",
              title: {
                en: "Connection (example: husband, wife, etc.)",
                "fr-CD": "Lien (exemple : époux, épouse, etc.)",
              },
              isRequired: required,
            },
            {
              type: "text",
              name: "custom_text_27",
              title: {
                en: "Address",
                "fr-CD": "Adresse",
              },
              isRequired: required,
            },
            {
              type: "phone",
              name: "custom_text_28",
              title: {
                en: "Telephone number",
                "fr-CD": "Téléphone",
              },
              isRequired: required,
              validators: [
                expressions.phone_en("custom_text_28"),
                expressions.phone_length("custom_text_28"),
              ],
            },
          ],
        },
        {
          type: "panel",
          name: "references_2_panel",
          title: {
            en: "Reference, 2nd degree family member",
            "fr-CD": "Membre de famille 2ème degré",
          },
          isRequired: required,
          elements: [
            {
              type: "text",
              name: "custom_text_29",
              title: {
                en: "Name",
                "fr-CD": "Nom et Prénom",
              },
              isRequired: required,
            },
            {
              type: "text",
              name: "custom_text_56",
              title: {
                en: "Connection (example: husband, wife, etc.)",
                "fr-CD": "Lien (exemple : époux, épouse, etc.)",
              },
              isRequired: required,
            },
            {
              type: "text",
              name: "custom_text_30",
              title: {
                en: "Address",
                "fr-CD": "Adresse",
              },
              isRequired: required,
            },
            {
              type: "phone",
              name: "custom_text_31",
              title: {
                en: "Telephone number",
                "fr-CD": "Téléphone",
              },
              isRequired: required,
              validators: [
                expressions.phone_en("custom_text_31"),
                expressions.phone_length("custom_text_31"),
              ],
            },
          ],
        },
      ]
    }
  ],
};

const page_3 = {
  name: "information_on_the_activity_of_the_project_leader",
  title: {
    en: "INFORMATION ON THE ACTIVITY OF THE PROJECT LEADER",
    "fr-CD": "INFORMATION SUR L'ACTIVITE DU PORTEUR DU PROJET",
  },
  elements: [
    {
      type: "text",
      name: "custom_text_32",
      title: {
        en: "Company name",
        "fr-CD": "Nom de l'entreprise",
      },
      isRequired: required,
    },
    {
      type: "text",
      name: "custom_text_33",
      title: {
        en: "Core business",
        "fr-CD": "Activité Principale",
      },
      isRequired: required,
    },
    {
      type: "text",
      name: "custom_float_1",
      title: {
        en: "Own funds (USD)",
        "fr-CD": "Fonds propre (USD)",
      },
      isRequired: required,
      inputType: "number",
    },
    {
      type: "checkbox",
      name: "custom_jsonfield_3",
      title: {
        en: "Activity Sectors",
        "fr-CD": "Secteurs d'activité",
      },
      isRequired: required,
      choices: [
        {
          value: "éducation",
          text: {
            en: "Education",
            "fr-CD": "Éducation"
          }
        },
        {
          value: "agriculture",
          text: "Agriculture",
        },
        {
          value: "élevage",
          text: {
            en: "Breeding",
            "fr-CD": "Elevage",
          },
        },
        {
          value: "artisant",
          text: {
            en: "Artisan",
            "fr-CD": "Artisant",
          },
        },
        {
          value: "restauration",
          text: {
            en: "Catering",
            "fr-CD": "Restauration",
          },
        },
        {
          value: "santé",
          text: {
            en: "Health",
            "fr-CD": "Santé",
          },
        },
        {
          value: "transformation",
          text: "Transformation",
        },
        {
          value: "production",
          text: "Production",
        },
        {
          value: "services",
          text: "Services",
        },
      ],
      hasOther: true,
      storeOthersAsComment: false,
      otherText: {
        en: "Other (specify)",
        "fr-CD": "Autre (à préciser)",
      },
    },
    {
      type: "panel",
      name: "headquarters_address_panel",
      title: {
        en: "Address of the headquarters",
        "fr-CD": "Adresse du siège",
      },
      isRequired: required,
      elements: [
        {
          type: "text",
          name: "custom_text_35",
          title: {
            en: "Head Office address",
            "fr-CD": "Social",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_36",
          title: {
            en: "Operational offices address",
            "fr-CD": "D'exploitation",
          },
          isRequired: required,
        },
        {
          type: "dropdown",
          name: "custom_text_54",
          title: {
            en: "Property situation",
            "fr-CD": "Situation de propriété",
          },
          isRequired: required,
          choices: [
            {
              value: "proprietaire",
              text: {
                en: "Owner",
                "fr-CD": "Propriétaire",
              },
            },
            {
              value: "locataire",
              text: {
                en: "Tenant",
                "fr-CD": "Locataire",
              },
            },
            {
              value: "sous_loge",
              text: {
                en: "Subtenant",
                "fr-CD": "Sous-Logé",
              },
            },
          ],
          hasOther: true,
          storeOthersAsComment: false,
          otherText: {
            en: "Other",
            "fr-CD": "Autres",
          },
        },
        {
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_3",
          title: {
            en: "Upload document of business plan",
            "fr-CD": "Télécharger le plan d'affaires",
          },
          isRequired: required,
        },
      ],
    },
    {
      type: "panel",
      name: "company_other_person_panel",
      title: {
        en: "Other person engaged in the company",
        "fr-CD": "Autre personne pouvant engager l'entreprise",
      },
      isRequired: required,
      elements: [
        {
          type: "text",
          name: "custom_text_37",
          title: {
            en: "Name",
            "fr-CD": "Nom et Prénom",
          },
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_38",
          title: {en: "Title", "fr-CD": "Titre"},
          isRequired: required,
        },
        {
          type: "text",
          name: "custom_text_39",
          title: {
            en: "Address",
            "fr-CD": "Adresse",
          },
          isRequired: required,
        },
        {
          type: "phone",
          name: "custom_text_40",
          title: {
            en: "Phone number",
            "fr-CD": "Téléphone",
          },
          isRequired: required,
          validators: [
            expressions.phone_en("custom_text_40"),
            expressions.phone_length("custom_text_40"),
          ],
        },
      ],
    },
  ],
};

const page_4 = {
  name: "level_of_formalization",
  title: {
    en: "LEVEL OF FORMALIZATION",
    "fr-CD": "NIVEAU DE FORMALISATION",
  },
  elements: [
    {
      type: "dropdown",
      name: "custom_text_53",
      title: {
        en: "Legal form of activity",
        "fr-CD": "Forme juridique de l'activité (Etablissement, SARL, SPRL,…)",
      },
      isRequired: required,
      choices: [
        {
          value: "etablissement",
          text: {
            en: "Establishment",
            "fr-CD": "Etablissement",
          },
        },
        {
          value: "sarl",
          text: "SARL",
        },
        {
          value: "sa",
          text: "SA",
        },
        {
          value: "sas",
          text: "SAS",
        },
        {
          value: "sarlu",
          text: "SARLU",
        },
        {
          value: "asbl_ou_ngo",
          text: {
            en: "ASBL or NGO",
            "fr-CD": "ASBL ou ONG",
          },
        },
        {
          value: "gie",
          text: {
            en: "GIE (Economic Interest Group)",
            "fr-CD": "GIE (Groupe d'intérêt Économique)",
          },
        },
      ],
    },
    {
      type: "panel",
      name: "numbers_and_documents_panel",
      title: {
        en: "Numbers and Documents",
        "fr-CD": "Numéros et Documents",
      },
      isRequired: required,
      elements: [
        {
          type: "text",
          name: "custom_text_49",
          title: {
            en: "RCCM Number format",
            "fr-CD":
              "Numéro de Registre de Commerce et de Crédit Mobilier (RCCM)",
          },
          isRequired: required,
          placeHolder: "CDAAARCCM22A1111",
          visibleIf: `{custom_text_53} == 'etablissement'
            or {custom_text_53} == 'sarl'
            or {custom_text_53} == 'sa'
            or {custom_text_53} == 'sas'
            or {custom_text_53} == 'sarlu'`,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter 17 characters without special characters: /,-",
                "fr-CD": "Veuillez saisir 17 caractères sans caractères spéciaux : /,-",
              },
              regex: `^[A-Za-z0-9]{17}$`,
            },
          ],
        },
        {
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_5",
          title: {
            en: "RCCM Document",
            "fr-CD":
              "Document de Registre de Commerce et de Crédit Mobilier (RCCM)",
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'etablissement'
            or {custom_text_53} == 'sarl'
            or {custom_text_53} == 'sa'
            or {custom_text_53} == 'sas'
            or {custom_text_53} == 'sarlu'`,
        },
        {
          type: "text",
          name: "custom_text_50",
          title: {
            en: "IDNAT Number format",
            "fr-CD": "Numéro d'Identification Nationale (IDNAT)",
          },
          isRequired: required,
          placeHolder: "11A1111A11111A",
          visibleIf: `{custom_text_53} == 'etablissement'
            or {custom_text_53} == 'sarl'
            or {custom_text_53} == 'sa'
            or {custom_text_53} == 'sas'
            or {custom_text_53} == 'sarlu'`,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter 14 characters without special characters: /,-",
                "fr-CD": "Veuillez saisir 14 caractères sans caractères spéciaux : /,-",
              },
              regex: `^[A-Za-z0-9]{14}$`,
            },
          ],
        },
        {
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_6",
          title: {
            en: "IDNAT Document",
            "fr-CD": "Document d'Identification Nationale (IDNAT)",
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'etablissement'
            or {custom_text_53} == 'sarl'
            or {custom_text_53} == 'sa'
            or {custom_text_53} == 'sas'
            or {custom_text_53} == 'sarlu'`,
        },
        {
          type: "text",
          name: "custom_text_51",
          title: {
            en: "Tax Number format",
            "fr-CD": "Numéro d'Impôt fiscal",
          },
          isRequired: required,
          placeHolder: "A2044946C",
          visibleIf: `{custom_text_53} == 'etablissement'
            or {custom_text_53} == 'sarl'
            or {custom_text_53} == 'sa'
            or {custom_text_53} == 'sas'
            or {custom_text_53} == 'sarlu'`,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter 9 characters",
                "fr-CD": "Veuillez saisir 9 caractères",
              },
              regex: `^[A-Za-z0-9]{9}$`,
            },
          ],
        },
        {
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_7",
          title: {
            en: "Tax Document",
            "fr-CD": "Document d'Impôt fiscal",
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'etablissement'
            or {custom_text_53} == 'sarl'
            or {custom_text_53} == 'sa'
            or {custom_text_53} == 'sas'
            or {custom_text_53} == 'sarlu'`,
        },
        {
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "file_8",
          title: {
            en: "Notarised status of the company",
            "fr-CD": "Statut Notarié de la société",
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'sarl'
            or {custom_text_53} == 'sa'
            or {custom_text_53} == 'sas'
            or {custom_text_53} == 'sarlu'
            or {custom_text_53} == 'asbl_ou_ngo'
            or {custom_text_53} == 'gie'`,
        },
        {
          type: "matrixdynamic",
          name: "custom_jsonfield_4",
          title: {
            en: "The partners",
            "fr-CD": "Les associés",
          },
          minRowCount: 1,
          rowCount: 1,
          maxRowCount: 10,
          addRowText: {
            en: "Add",
            "fr-CD": "Ajouter",
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'sa'`,
          columns: [
            {
              cellType: "text",
              name: "nom",
              title: {
                en: "Name",
                "fr-CD": "Nom",
              },
            },
            {
              cellType: "text",
              name: "prenom",
              title: {
                en: "Last Name",
                "fr-CD": "Prénom",
              },
            },
            {
              cellType: "phone",
              name: "phone",
              title: {
                en: "Telephone",
                "fr-CD": "No de Tél",
              },
              validators: [
                expressions.phone_en("phone"),
                expressions.phone_length("phone"),
              ],
            },
            {
              cellType: "text",
              name: "address",
              title: {
                en: "Address",
                "fr-CD": "Adresse physique",
              },
            },
            {
              cellType: "text",
              inputType: "email",
              name: "email",
              title: {
                en: "Email",
                "fr-CD": "Email",
              },
            },
          ],
        },
        {
          type: "text",
          name: "pending1",
          title: {
            en: "Internal Regulations",
            "fr-CD": "Règlement d'Ordre Intérieur",
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'asbl_ou_ngo'
          or {custom_text_53} == 'gie'`,
        },
        {
          type: "text",
          name: "pending2",
          title: {
            en: "Operating authorisation from the supervisory ministry (depending on the sector of activity)",
            "fr-CD": "Autorisation de Fonctionnement du Ministère de Tutelle (selon les secteurs d'activités)"
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'asbl_ou_ngo'`,
        },
        {
          type: "text",
          name: "custom_text_52",
          title: {
            en: "F92",
            "fr-CD": "F92"
          },
          isRequired: required,
          visibleIf: `{custom_text_53} == 'asbl_ou_ngo'`,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter F92 followed by 5 digits",
                "fr-CD": "Veuillez saisir F92 suivi de 5 chiffres",
              },
              regex: `^(F|f)92[0-9]{5}$`,
            },
          ],
        },
      ],
    },
    {
      type: "dropdown",
      name: "custom_text_46",
      title: {
        en: "Professional/affiliation card with an Incubator",
        "fr-CD": "Carte professionnelle / d'affiliation auprès d'un Incubateur",
      },
      isRequired: required,
      choices: [
        {
          value: "oui",
          text: {
            en: "Yes",
            "fr-CD": "Oui",
          },
        },
        {
          value: "non",
          text: {
            en: "No",
            "fr-CD": "Non",
          },
        },
      ],
    },
    {
      type: "file_uploader",
      accept: "image/*,application/pdf",
      name: "file_4",
      visibleIf: "{custom_text_46} = 'oui'",
      title: {
        en: "Upload proof",
        "fr-CD": "Télécharger la carte d'affiliation"
      },
      isRequired: required,
    },
    {
      type: "dropdown",
      name: "custom_text_47",
      title: {
        en: "Existence of accounting",
        "fr-CD": "Existence de la comptabilité",
      },
      isRequired: required,
      choices: [
        {
          value: "oui",
          text: {
            en: "Yes",
            "fr-CD": "Oui",
          },
        },
        {
          value: "non",
          text: {
            en: "No",
            "fr-CD": "Non",
          },
        },
      ],
    },
    {
      type: "checkbox",
      name: "custom_jsonfield_5",
      title: {
        en: "Payment of taxes and fees",
        "fr-CD": "Paiement d'impôts et taxes",
      },
      isRequired: required,
      choices: [
        "IPR",
        "INPP",
        "CNSS",
        "IBP",
        "TVA"
      ],
    },
  ],
};

const page_5 = {
  name: "information_on_previous_financing",
  title: {
    en: "INFORMATION ON PREVIOUS FINANCING",
    "fr-CD": "INFORMATION SUR LES FINANCEMENS ANTERIEURS",
  },
  elements: [
    {
      type: "matrixdynamic",
      name: "custom_jsonfield_1",
      titleLocation: "hidden",
      minRowCount: 1,
      rowCount: 1,
      maxRowCount: 10,
      addRowText: {
        en: "Add",
        "fr-CD": "Ajouter",
      },
      isRequired: required,
      columns: [
        {
          cellType: "text",
          name: "nom_du_financeur",
          title: {
            en: "Name of funder",
            "fr-CD": "Nom du Financeur",
          },
          isRequired: required,
        },
        {
          cellType: "text",
          name: "type_de_financement",
          title: {
            en: "Type of financing",
            "fr-CD": "Type de Financement",
          },
          isRequired: required,
        },
        {
          cellType: "text",
          name: "montant_du_financement",
          title: {
            en: "Funding amount",
            "fr-CD": "Montant du Financement",
          },
          isRequired: required,
          inputType: "number",
        },
        {
          cellType: "text",
          name: "statuts",
          title: {
            en: "Status",
            "fr-CD": "Statuts",
          },
          isRequired: required,
          description: {
            en: "Quantity by Unit price",
            "fr-CD": "Quantité par prix unitaire"
          },
        },
      ],
    },
  ],
};

const page_6 = {
  name: "detailed_financing_plan",
  title: {
    en: "DETAILED FINANCING PLAN",
    "fr-CD": "PLAN DE FINANCEMENT DETAILLE",
  },
  elements: [
    {
      type: "matrixdynamic",
      name: "custom_jsonfield_2",
      titleLocation: "hidden",
      minRowCount: 1,
      rowCount: 1,
      maxRowCount: 50,
      addRowText: {
        en: "Add",
        "fr-CD": "Ajouter",
      },
      isRequired: required,
      columns: [
        {
          cellType: "text",
          name: "description_d_articles",
          title: {
            en: "Item description",
            "fr-CD": "Description d'articles",
          },
          isRequired: required,
        },
        {
          cellType: "text",
          name: "quantité",
          title: {
            en: "Quantity",
            "fr-CD": "Quantité",
          },
          isRequired: required,
          inputType: "number",
        },
        {
          cellType: "text",
          name: "prix_unitaire",
          title: {
            en: "Unit price",
            "fr-CD": "Prix Unitaire",
          },
          isRequired: required,
          inputType: "number",
        },
        {
          cellType: "expression",
          name: "prix_total",
          title: {
            en: "Total price",
            "fr-CD": "Prix Total",
          },
          expression: "{row.quantité} * {row.prix_unitaire}"
        },
        {
          cellType: "file_uploader",
          accept: "image/*,application/pdf",
          name: "pièce_jointe",
          title: {
            en: "Attachment",
            "fr-CD": "Pièce jointe"
          },
        },
      ],
    },
    {
      type: "expression",
      name: "custom_float_2",
      title: {
        en: "Total requested",
        "fr-CD": "Total demandé",
      },
      expression:
        "fixed(sumMatrixdropdown({custom_jsonfield_2}, 'prix_total'), 2)",
      displayStyle: "currency",
      currency: "USD",
    },
    {
      type: "text",
      name: "custom_float_3",
      title: {
        en: "Own funds financing (contribution of the bearer)",
        "fr-CD": "Apport du porteur",
      },
      isRequired: required,
      inputType: "number",
    },
    {
      type: "expression",
      name: "custom_float_4",
      title: {
        en: "Financing by external funds",
        "fr-CD": "Financement par Fonds Exterieur",
      },
      expression: "{custom_float_2} - {custom_float_3}",
      displayStyle: "currency",
      currency: "USD",
    },
  ],
};

const page_7 = {
  name: "information_on_the_financing_request",
  title: {
    en: "INFORMATION ON THE FINANCING REQUEST",
    "fr-CD": "INFORMATION SUR LA DEMANDE DE FINANCEMENT",
  },
  elements: [
    {
      type: "dropdown",
      name: "custom_text_42",
      title: {
        en: "Request types",
        "fr-CD": "Type de Demande",
      },
      isRequired: required,
      choices: [
        {
          value: "garantie_bancaire",
          text: {
            en: "Bank guarantee",
            "fr-CD": "Garantie Bancaire",
          },
        },
      ],
    },
    {
      type: "text",
      name: "amount",
      title: {
        en: "Amount requested USD",
        "fr-CD": "Montant sollicité",
      },
      isRequired: required,
      inputType: "number",
      validators: [
        {
          type: "numeric",
          minValue: 5000,
          text: {
            en: "Amount should be between 5.000 a 100.000 USD",
            "fr-CD": "Le montant doit être compris entre 5 000 et 100 000 USD",
          },
        },
        {
          type: "numeric",
          maxValue: 100000,
          text: {
            en: "Amount should be between 5.000 a 100.000 USD",
            "fr-CD": "Le montant doit être compris entre 5 000 et 100 000 USD",
          },
        },
      ],
    },
    {
      type: "dropdown",
      name: "custom_text_43",
      title: {
        en: "Use of funds",
        "fr-CD": "Utilisation de fonds",
      },
      isRequired: required,
      choices: [
        {
          value: "investissement_d_equipement",
          text: {
            en: "Equipment investment",
            "fr-CD": "Investissement d'équipement",
          },
        },
        {
          value: "fonds_de_roulement",
          text: {
            en: "Working capital",
            "fr-CD": "Fonds de roulement",
          },
        },
        {
          value: "mixte",
          text: {
            en: "Mixed",
            "fr-CD": "Mixte",
          },
        },
      ],
    },
    {
      type: "text",
      name: "custom_float_6",
      title: {
        en: "Estimated repayment capacity (per month)",
        "fr-CD": "Capacité de remboursement estimée (par mois)",
      },
      isRequired: required,
      inputType: "number",
    },
    {
      type: "dropdown",
      name: "repayment_period",
      title: {
        en: "Term of repayment",
        "fr-CD": "Echéancier souhaité",
      },
      isRequired: required,
      choices: [
        {
          value: 24,
          text: {
            en: "24 months",
            "fr-CD": "24 mois",
          },
        },
      ],
    },
  ],
};

export const intake_fogec = [
  // Page 1: SUMMARY PRESENTATION
  page_1,
  // Page 2: INFORMATION ON THE PROJECT LEADER
  page_2,
  // Page 3: INFORMATION ON THE ACTIVITY OF THE PROJECT LEADER
  page_3,
  // Page 4: INFORMATION ON PREVIOUS FINANCING
  page_4,
  // Page 5: DETAILED FINANCING PLAN
  page_5,
  // Page 6: INFORMATION ON THE FINANCING REQUEST
  page_6,
  // Page 7: LEVEL OF FORMALIZATION
  page_7,
];
