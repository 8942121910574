import intl from "react-intl-universal";
import { api, logger } from "../app";
import Config from "../config";

export const DEFAULT_LOCALE = "en";

export const all_locales = [
  "ar",
  "en",
  "rw",
  "rn",
  "es-GT",
  "es-MX",
  "es-HN",
  "fr-BI",
  "fr-CD",
  "fr-TD",
];

const warningHandler = (message, detail) => {
  logger.leaveBreadcrumb("Translation error:", { message, detail });
  logger.captureException(new Error("Translation error"));
};

export function loadLocales(currentLocale = "en") {
  console.log("loading locale: ", currentLocale);
  console.log(api.endpoint.locale_files_url);

  // NB locale must be formatted with hyphen and not underscore. e.g. es-GT vs es_GT. The latter will cause errors.
  //locale filenames on the backend use underscores locale.es_GT.json
  const localeFileName = currentLocale.replace("-", "_");

  // 'ar/rw' defaults are not auto added by intl, but the resource is available on alicdn
  // this avoids the "Language ${lang} is not supported" warning
  const commonLocaleDataUrls = intl.getInitOptions().commonLocaleDataUrls || {};
  if (!commonLocaleDataUrls[localeFileName]) {
    commonLocaleDataUrls[localeFileName] = commonLocaleDataUrls.en.replace(
      "/en.js",
      `/${currentLocale}.js`
    );
  }
  const localeFileFolder =
    Config.deployment && ["mfw"].includes(Config.deployment)
      ? ""
      : `config/${Config.deployment}/`;
  return fetch(
    `${api.endpoint.locale_files_url}/${localeFileFolder}locale_${localeFileName}.json`,
    { method: "GET" }
  ) //
    .then((res) => res.json())
    .then((data) => {
      // init method will load CLDR locale data according to currentLocale
      intl.init({
        commonLocaleDataUrls,
        currentLocale,
        locales: {
          [currentLocale]: data,
        },
        warningHandler,
      });
      console.log("locale loaded", currentLocale);
      return currentLocale;
    });
}

export function translate(key, variables) {
  // Translates the message, and if not existing shows the key
  // variables either as dictionary or es2016 dic, so: {guarantor_phone} or {guarantor_phone: 123}
  return intl.get(key, variables).d(key);
}

export function translateList(key) {
  return intl.get(key);
}

export function translateHTML(key, variables) {
  // Translates the message, and if not existing shows the key
  return intl.get(key, variables).d(key);
}

export function register_format_currency(Survey) {
  /*
  Function returning formatted amount
  Params:
  0: amount, valid float or integer
  1: currency code, default='MXN'
  2: locale, default='es-MX'
  */
  Survey.FunctionFactory.Instance.register("formatCurrency", (params) => {
    let amount = params[0];
    let currency_code = params[1] || "MXN";
    let locale = params[2] || "es-MX";
    let currency_formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency_code,
    });
    return currency_formatter.format(amount).normalize("NFKD") || undefined;
  });
}
