import React from 'react';
import { connect } from 'react-redux';
import * as Survey from 'survey-react';
import { Bullet } from '../../components/bullet/bullet';
import { Footer } from '../../components/footer/footer';
import HelpComponent from '../../components/help/help';
import { Start } from '../../components/start/start';
import Config from '../../config';
import { translate, translateList } from '../../lib/intl';
import { loanActions } from '../../store/store';
import { extractSurveyData } from '../../survey/survey.utils';
import '../../theme/survey.scss';
import { choose_flow } from './choose_flow';
import './choose_flow.container.scss';

class ChooseFlowContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    // setup model
    this.model = new Survey.Model(choose_flow(props.device.locale));

    // restore data
    this.model.data = this.base = {
      ...this.model.data,
      ...this.props.loan.intake,
      purpose_question: this.props.loan.loan_purpose,
    };

    // set callbacks
    this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
    this.model.onCompleting.add((survey) => this.onCompleting(survey));

    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };

    this.descList = [];
    this.bullets = [];
    try {
      this.bullets = translateList('start_intake.bullets').map((bullet, idx) =>
        Bullet(translate(bullet), idx)
      );
      this.descList = translateList('start_intake.desc_list').map(
        // eslint-disable-next-line sonarjs/no-identical-functions
        (desc, idx) => (
          <p className="spacer" key={idx}>
            {translate(desc)}
          </p>
        )
      );
    } catch (e) {
      console.error(`Unable to translate ${e}, check translation key is added in locale file `);
    }
  }

  onPartialSend(survey) {
    this.props.saveIntake(this.props.loan.uuid, extractSurveyData(survey, this.state, this.base));
  }

  // @TODO @Themba requires functionality to take the correct flow for new or existing clients Banco Popular
  async onCompleting(survey) {
    let state = 'applying_educational_intake';
    let next_page;

    if (survey.data.purpose_question === 'business') {
      state = 'applying_intake';
    }

    if (survey.data.purpose_question === 'new_client') {
      state = 'new_client';
      next_page = 'credit_check';
    }

    if (survey.data.purpose_question === 'existing_client') {
      state = 'existing_client';
      next_page = 'credit_check';
    }

    await this.props.saveIntake(
      this.props.loan.uuid,
      extractSurveyData(survey, state, this.base),
      false
    );

    await this.props
      .setFlow(this.props.loan.uuid, state, survey.data.purpose_question)
      .then(() => this.props.setPage(next_page ? next_page : state));
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time: this.state.help_time + Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render = ({ history, account, device, loan } = this.props) => (
    <div className="chooseFlowContainer">
      <HelpComponent
        onClose={() => this._closeHelp()}
        onOpen={() => this._openHelp()}
        type="home"
        locale={device.locale}
      />

      {this.state.is_started || Config.skip_start ? (
        <div className="centerWrapper">
          <Survey.Survey model={this.model} locale={device.locale} />
        </div>
      ) : (
        <div>
          <Start
            stage="intake"
            time={Config.has_no_container_time ? '0' : '20'}
            onStart={() => this.setState({ is_started: true })}
          >
            <p className="heavy">{translate('start_intake.desc1')}</p>
            <p className="spacer">{translate('start_intake.desc2')}</p>
            {this.descList}
            {this.bullets}
          </Start>
          <Footer />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveIntake: (uuid, assessment, submit) =>
      dispatch(loanActions.saveIntake(uuid, assessment, submit)),
    setFlow: (uuid, state, purpose) => dispatch(loanActions.setFlow(uuid, state, purpose)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(mapStateToProps, mapDispatchToProps)(ChooseFlowContainer);

export { connectedContainer as ChooseFlowContainer };
